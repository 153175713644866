<template>
  <base-layout>
    <!--header-bar></header-bar-->
    <ion-content>
      <div>
        <div v-if="!user" style="padding:10px; font-weight: bold">Please login to view this content</div>
        <div class="container-fluid p-0 p-md-3" >
          <div
              class="row row-cols-1 row-cols-md-3  row-cols-lg-4 row-cols-xl-5 g-2 g-md-4 row-eq-height justify-content-start">
            <template v-for="session in sessions" :key="session.id">
              <session-item :session="session" v-if="session"></session-item>
            </template>

          </div>
        </div>
      </div>
    </ion-content>
  </base-layout>
</template>

<script>
import {IonContent, alertController} from '@ionic/vue';
import {defineComponent} from 'vue';
import {menuController} from "@ionic/vue";
import {mapActions, mapGetters, mapState} from 'vuex';
import moment from 'moment';
import {gridOutline} from 'ionicons/icons';
import SessionItem from "@/components/SessionItem";
//moment.locale("de");
export default defineComponent({
  name: 'Home',
  data() {
    return {
      sessions: [],
      days: {},
      activeDay: '',
      search: "",
      filtered: [],
      location : null,
      isOnDemand :false
    }
  },

  setup() {
    return {
      gridOutline,
    }
  },
  components: {
    IonContent,
    'session-item':SessionItem
  },
  computed: {
    ...mapState('sync', ['updateFinished']),
    ...mapGetters('auth', ['user']),
    filteredSessions: function () {
      return this.filtered.length ? this.filtered : this.sessions;
    }
  },
  methods: {
    ...mapActions('sessions', ['getAllSessions', 'getSessionListLive','getSessionsForLocationInSlot']),
    ...mapActions('googleanalytics', ['trackWithLabel']),
    openMenu() {
      menuController.open("app-menu")
    },

    filter(term) {
      if (term.length > 0) {
        this.filtered = this.sessions.filter(session =>
            Object.keys(session).some(k =>
                session[k]
                    ? session[k].toString().toLowerCase().includes(term.toLowerCase())
                    : ""
            )
        );
        this.filtered = this.groupList(this.filtered, 'start_time');
        this.days = {};
        this.prepareDays(this.filtered);
      } else {
        this.filtered = [];
        this.resetList();
      }
    },
    resetList() {
      this.days = {};
      this.prepareDays(this.sessions);
    },
    async fetchSessions(payload) {

      this.sessions = (await this.getSessionsForLocationInSlot(payload)).sort((a, b) => {
        return new Date(a.start_time) - new Date(b.start_time);
      });

      this.sessions = this.groupList(this.sessions, 'start_time');
      this.days = {};
      this.prepareDays(this.sessions);
    },
    groupList(list, selector) {
      let letters = [];
      let currentCharCode = '2010-01-01T00:00:00'; //$filter('date')(date, format, timezone)
      let sortedList = [];

      function addLetter(code) {
        let letter = code
        sortedList.push({
          isHead: true,
          head: letter,
          start_time: letter
        });
        letters.push(letter);
      }

      (list || []).forEach(function (item) {
        if (item[selector]) {
          let itemCharCode = item[selector];
          let difference = new Date(itemCharCode) - new Date(currentCharCode);
          if (difference > 0)
            addLetter(itemCharCode);
          currentCharCode = itemCharCode;
          sortedList.push(item);
        }
      });
      return sortedList;
    },
    prepareDays(sessions) {
      for (let i = 0; i < sessions.length; i++) {
        let session = sessions[i];
        if (session.start_time) {
          let start = session.start_time.split('T')[0];
          if (!this.activeDay) {
            this.activeDay = start;
          }
          if (!this.days[start]) {
            this.days[start] = {};
            this.days[start].label = moment(start).format('dddd, MMMM D')
            this.days[start].sessions = [];
          }
          this.days[start].sessions.push(session);
        }
      }
    },

    getChairPicture(session) {
      let link = ''
      if (session.chair && session.chair[0] && session.chair[0].vitae_picture) {
        link = 'https://fileserver-188ab.kxcdn.com/file/' + session.chair[0].vitae_picture.split('/')[4];
      }
      return link;
    },

    changeToGrid() {
      this.$router.push('/app/gridList');
    },

    async showDisclaimer(session) {
      if (session.disclaimer && session.disclaimer.length > 10) {
        const alert = await alertController
            .create({
              cssClass: 'my-custom-class',
              header: session.title,
              message: '<strong>' + session.disclaimer + '</strong>',
              buttons: [
                {
                  text: 'Decline',
                  role: 'cancel',
                  cssClass: 'secondary',
                  id: 'cancel-button',
                  handler: blah => {
                    console.log('Confirm Cancel:', blah)
                  },
                },
                {
                  text: 'Accept',
                  id: 'confirm-button',
                  handler: () => {
                    this.trackWithLabel(session.title);
                    this.$router.push('/app/sessions/' + session.id);
                  },
                },
              ],
            });
        return alert.present();
      } else {
        this.$router.push('/app/sessions/' + session.id);
      }

    }


  },
  async created() {

    this.isOnDemand = this.$route.params.room == 'da7ad0c0-3ed1-4500-1302-080000000018';
    this.fetchSessions({location:this.$route.params.room, start:this.$route.params.start, end:this.$route.params.end});
    this.activeDay = this.$route.params.day;
    this.moment = moment;
  },
  watch: {
    updateFinished: async function (newVal) {
      if (newVal) {
        this.fetchSessions({location:this.$route.params.room, start:this.$route.params.start, end:this.$route.params.end})
      }
    },
  },
});
</script>
<style lang="scss">

</style>
